<script>
import { nextTick } from 'vue'
import { useRouter } from 'vue-router/composables'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import LoyaltyCardForm from '/~/drawer/loyalty-cards/components/card-form.v2.vue'
import LoyaltyCreateV1 from '/~/drawer/loyalty-cards/loyalty-create.vue'

export default {
  name: 'loyalty-membership-create',
  components: {
    LoyaltyCardForm,
    BaseAsidePage,
  },
  extends: LoyaltyCreateV1,
  setup() {
    const router = useRouter()

    function onUpdated(id) {
      router.replace({ hash: `#profile-loyalty-card-details/${id}` })
    }

    return {
      ...LoyaltyCreateV1.setup?.(...arguments),
      onUpdated,
    }
  },
  methods: {
    setFocus() {
      nextTick(() => {
        this.$refs.baseAsidePage.setFocus()
      })
    },
  },
}
</script>

<template>
  <base-aside-page
    ref="baseAsidePage"
    :title="`Add ${card ? card.name : 'Card'}`"
    no-padding
  >
    <div class="min-h-full px-5 pb-5">
      <div v-if="isCatalogCardsLoading" class="space-y-[15px]">
        <div class="h-5 animate-pulse rounded-xl bg-strong" />
        <div class="h-5 animate-pulse rounded-xl bg-strong" />
        <div class="h-5 animate-pulse rounded-xl bg-strong" />
      </div>

      <loyalty-card-form v-else-if="card" :card="card" @updated="onUpdated" />
    </div>
  </base-aside-page>
</template>
